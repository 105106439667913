@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3;
    /* Light grey */
    border-top: 10px solid var(--blue);
    /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}
.loginloading{
    color: #d3aaaa;
    width: 65px;
    height: 65px;
    position: absolute;
    z-index: 4;
    top: 61%;
    left: 40VW;
}