.register {
    width:100% !important;
    height: auto !important;
    margin: auto;
    background-color: var(--white) !important;
    box-shadow: 0px 3.2828px 10.5656px rgba(0, 0, 0, 0.25) !important;
    border-radius: 2.5rem !important;
}
.form-control :active{
    border: 1px solid var(--blue) !important;

}

.form-control:hover {
    border: 0px 1.32767px 10.6214px rgba(61, 60, 60, 0.05) solid 1px !important;
}

.otp {
    width: 50px !important;
    height: 50px !important;
    margin: auto;
    background-color: var(--white) !important;
    box-shadow: 0px 5.2828px 10.5656px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
    border: 1px solid var(--blue) !important;
}

.reg-Buttons {
    width: 100%;
    height: auto;
    background-color: var(--white);
    margin: auto;
    border: none;
    font-weight: 500;
    padding: 0.7rem;
    box-shadow: 0px 3.60994px 3.60994px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    text-align: center;
}
/* .btn-primaryReg {
    background-color: var(--blue) !important;
    box-shadow: 0px 5.66667px 11.3333px rgba(211, 38, 38, 0.25) !important;
    border-radius: 35.4167px !important;
} */

.selectSchool{
    /* border: 1px solid black; */
    margin-left: 5px;
    display: flex;
    align-items: center !important;
}
.selectSchool p{
    margin-left: 5px;
    margin-top: 15px;
}
.selectSchoolinput{
    width: 100%;
    box-shadow: 0px 3.2828px 10.5656px rgba(0, 0, 0, 0.25) !important;
    border: none;
}
.optionss{
    border: 2px solid green;
}





/* --------------  Otp box  -------------------- */